
@media(max-width:1920px) and (min-width:1367px) {
    .history-bg{
        .container{
            max-width: 1690px!important;
        }
        .all-page-inner{
            height: 800px;
            margin-top: 90px;
        }
    }        
}

@media (max-width: 1599px) {
	.gradiant-box::after {
        box-shadow: 25px 133px 708px 776px #fffbd8eb;
        right: 0px;
        z-index: 9;
        top: -481px;
    }
    .gradiant-box::before {
        box-shadow: 0px 147px 338px 275px #fffad1cd;
        right: 233px;
        top: 90px;
    }
}

@media (max-width: 1366px) {
	
}

@media(max-width:1200px) {
   
	.providing-inner {
		padding: 60px 20px 0px 20px;
	}    
	
	// team page css start
	.team-count {
		h5 {
			font-size: 50px;
		}
	}
	.portfolio-left {
		h4 {
			margin-bottom: 10px;
		}
	}
	.director-box {
		.box-content.new {
			padding: 9px 5px 5px 15px;
		}
	}
	.partner-box {
		min-height: 180px;
	}
	
	// team page css end
}

@media(max-width:1024px) {
   
}

@media(max-width:1199px) and (min-width:992px) {
   
}

@media(max-width:991px) {
	.gradiant-box::before {
		box-shadow: 0px 147px 176px 160px #fffad1cd;
		right: 145px;
		top: -12px;
	}
	.gradiant-box::after {
		box-shadow: 22px 133px 472px 513px #fffbd8eb;
	}
}

@media(max-width:990px) and (min-width:768px) {
  
}
@media(max-width:991px) {
/* manu start */
  /* manu css start */

    .header-menu .col-sm-3 {
        flex: 0 0 50%!important;
        max-width: 50%!important;
    }
  
 /* manu start end */
}

@media(max-width:767px) {
 

 .shadow-blue {
    width: 30%;
}
.banner {
    background-size: cover;
} 

// team page css start
.team-count {
	h5 {
		font-size: 30px;
	}
	p {
		font-size: 12px;
	}
}
.director-box {
	.box-content.new {
		padding: 40px 20px 30px 30px;
	}
}
.directors {
	margin-top: 40px;
}
.sec.padding.dir {
	padding: 30px 0px 0px 0;
}
.directors-sec-last {
	padding-bottom: 20px !important;
}
.sec.padding.dir.new {
	padding: 34px 0px 40px 0;
}

// team page css end
}

@media(max-width:766px) {
 
}

@media(max-width:574px) {
 

    .header-menu .col-sm-3 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .top-menu-bar {
        justify-content: space-between;
    }
   
      /* manu css start */
      .shadow-blue {
		width: 0%;
	}
	
	

	
	.providing-head {
		h3 {
			font-size: 26px;
		}
	}

	// team page css start
	.director-box {
		.box-content.new {
			padding: 10px 8px 10px 10px;
		}
		.box-content {
			p {
				font-size: 14px;
			}
		}
	}
	.brown-head.new {
		h6 {
			font-size: 14px;
		}
	}
	
	// team page css end

	.gradiant-box::before {
        box-shadow: 38px 99px 109px 104px #fffad1cd;
    }
    .gradiant-box::after {
        box-shadow: 22px 87px 393px 419px #fffbd8eb;
    }
	// team page css start
	.director-box {
		flex-wrap: wrap;
		.box-img {
			width: 100%;
		}
		.box-content {
			width: 100%;
		}
		.box-content.new {
			padding: 24px 17px 18px 22px;
		}
	}
  
}

@media(max-width:414px) {
  
	
	.counts {
		.col-3 {
			max-width: 50% !important;
			flex: 0 0 50% !important;
		}
	}
	.brown-head.new {
		h6 {
			font-size: 12px;
		}
	}
	
	// team page css end
}

@media(max-width:375px) {
    
	.banner-icon {
		flex-wrap: wrap;
		.box {
			&:last-child {
				width: 100%;
				margin-top: 49px;
			}
		}
	}
	.shadow-blue {
		width: 100%;
	}
	.pro-box {
		.box-img {
			width: 191px;
			height: 191px;
		}
		.img-top {
			&::before {
				height: 121px;
				top: -2px;
				left: 18px;
				z-index: 9;
				transform: rotate(-34deg);
			}
			&::after {
				width: 58px;
				height: 84px;
				bottom: 75px;
				right: 19px;
				transform: rotate(-39deg);
			}
		}
	}
}


@media (max-width: 320px) {
	.pro-box {
		.img-top {
			&::before {
				height: 121px;
				top: 17px;
				left: 6px;
				z-index: 9;
				transform: rotate(0deg);
			}
			&::after {
				width: 58px;
				height: 84px;
				bottom: 34px;
				right: -10px;
				transform: rotate(-13deg);
			}
		}
	}
}








