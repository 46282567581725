// Transition mixin



@mixin transition($property...) {
    @each $prefix in "-webkit-", "-moz-", "-ms-", "-o-", "" {
        #{unquote($prefix)}transition: $property
    }
}

//Transform mixin
@mixin transform($property...) {
    @each $prefix in "-webkit-", "-moz-", "-ms-", "-o-", "" {
        #{unquote($prefix)}transform: $property
    }
}

//Placeholder mixin
@mixin placeholder {
    @each $prefix in "::-webkit-input-placeholder", ":-moz-placeholder", "::-moz-placeholder", ":-ms-input-placeholder" {
        &#{$prefix} {
            @content;
        }
    }
    color: #797979;
}

@mixin input-theme{
    height: 60px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    color: #000;
    font-size: 20px;
    &::placeholder{
        font-size: 20px;
        @include large{
        font-size: 12px;

        }
    }

    @include large{
        height: 40px;
        font-size: 12px;
    }
}

@mixin fit-content {
    width: intrinsic;          
    width: -moz-max-content;   
    width: -webkit-max-content; 
}

// buttons

@mixin theme-btn{
    background-color: #003661;
    border-radius: 0;
    padding: 6px 20px 8px 20px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 6px;
    @include till-large{
        padding: 6px 20px 8px 20px;
        font-size: 15px;
    }
    @include mobilePotrait{
        min-width: 100%;
    }

}

@mixin before-black{
    height: 100%;
    width: 100%;
    background: #0000009e;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
}




// fonts





//Media mixin
@mixin large {
    @media(max-width: 1599px) {
        @content;
    }
}
@mixin midLarge {
    @media(max-width: 1500px) {
        @content;
    }
}

@mixin till-large {
    @media(max-width: 1439px) {
        @content;
    }
}

@mixin medium {
    @media(max-width: 1200px) {
        @content;
    }
}

@mixin macbook {
    @media(max-width: 1199px) {
        @content;
    }
}

@mixin till-tablet {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin tablet {
    @media(max-width: 1035px) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin mobileLandscape {
    @media(max-width: 767px) {
        @content;
    }
}

@mixin mobilePotrait {
    @media(max-width: 576px) {
        @content;
    }
}

@mixin smallMobile {
    @media (max-width: 414px) {
        @content;
    }
}

@mixin miniMobile {
    @media (max-width: 375px) {
        @content;
    }
}


@mixin miniMobileEnd {
    @media (max-width: 320px) {
        @content;
    }
}
